





















































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import { Form } from "element-ui";
import { Adduser, getUser } from "@/api/users";
import { uuid } from "@/tool";
import * as consts from "@/utils/public-consts";
import * as _ from "lodash";
import {appTitle} from "@/utils/public-consts";

interface UserForm {
  username: string,
  password: string,
  psdValidDays: number,
  name: string,
  roles: string[],
  descr: string,
  userId: string
}

@Component({
  name: "SystemUserEditor",
  components: {
    cardBox
  }
})
export default class extends Vue {
  private rules = {
    username: [{ required: true, message: "请输入用户账号", trigger: "blur" }],
    // password: [{ required: true, message: "请输入用户密码", trigger: "blur" }],
    name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
    roles: [{ required: true, message: "请选择角色", trigger: "change" }]
  };
  private roles: { roleId: string, roleName: string }[] = consts.roles;
  private pageType: string = "";
  private title: string = "新增用户";
  private ruleForm: UserForm = {
    username: "",
    password: "",//Aa&123456
    psdValidDays: 0,
    name: "",
    roles: [],
    descr: "",
    userId: uuid()
  };

  private type(): string {
    if (this.pageType === "add") {
      this.title = "新增用户";
      this.$route.meta.title = "新增用户";
      document.title = `新增用户 | ${appTitle}`;
      return "立即创建";
    }
    if (this.pageType === "edit") {
      this.title = "编辑用户";
      this.$route.meta.title = "编辑用户";
      document.title = `编辑用户 | ${appTitle}`;
      return "保 存";
    }
    return "立即创建";
  }
  private submitForm() {
    (this.$refs.ruleForm as Form).validate((valid: boolean) => {
      if (valid) {
        Adduser(this.ruleForm).then((res: any) => {
          if (res.code === 0) {
            this.$message({
              message: this.pageType === "add" ? "添加成功" : "保存成功",
              type: "success"
            });
          }
        });
      } else {
        return false;
      }
    });
  }
  private doGoBackClick() {
    this.$router.go(-1);
  }
  mounted() {

    if (this.$route.query.userId) {
      getUser(this.$route.query.userId as string).then((res: any) => {
        console.log(`ruleForm before init: ${JSON.stringify(this.ruleForm)}`);
        //_.merge(this.ruleForm, res.data);
        Object.assign(this.ruleForm, res.data);
        console.log(`ruleForm after init: ${JSON.stringify(this.ruleForm)}`);
      });
    }

    this.pageType = this.$route.query.type as string;
  }
}
